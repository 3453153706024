:root {
    --shadow-default: 0 2px 6px 0 rgba(0, 0, 0, 0.2),
        0 3px 10px 0 rgba(0, 0, 0, 0.19);

    --shadow-default-hover: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);

    --shadow-bottom: 0 6px 6px -6px rgba(0, 0, 0, 0.2),
        0 8px 10px -10px rgba(0, 0, 0, 0.19);

    --main-color: #00a0a0;
    --unselected-color: #4b4b4bd0;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
        border-color 0.3s ease-in-out;
}

html {
    font-size: 10px;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
}

@media (min-width: 766px) and (max-width: 1199px) {
    html {
        font-size: 9px;
    }
}

@media (max-width: 765px) {
    html {
        font-size: 8px;
    }
}

body {
    font-size: 2.2rem;
    font-family: "Open Sans", Roboto, -apple-system, BlinkMacSystemFont,
        "Segoe UI", Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    scroll-behavior: smooth;
    overflow-x: hidden;
    animation: opacity-load 0.8s ease-in-out;
    height: 100%;
    width: 100%;
}

@keyframes opacity-load {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 100%;
    }
}

.root {
    width: 100%;
    height: 100%;
}

.App {
    width: 100%;
    height: 100%;
}

h1 {
    font-size: 3.5rem;
    font-weight: 100;
}

h2 {
    font-weight: 100;
    font-size: 3rem;
}

h3 {
    font-size: 2rem;
    margin: 1rem 0;
    font-weight: normal;
}

ul {
    list-style-type: none;
}

input,
textarea,
button,
select,
a,
div {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
    color: inherit;
    text-decoration: none;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
