.wrapper {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.project {
    width: 45rem;
    height: 30rem;
    background-size: contain;
    box-shadow: var(--shadow-default);
    margin: 1rem;
    flex-wrap: wrap;
    align-items: center;
    transition: box-shadow 0.3s ease-in-out;
    border-radius: 0.5rem;
}

@media (max-width: 380px) {
    .project {
        width: 36rem;
        height: 24rem;
    }
}

.project:hover > .project_details {
    opacity: 1;
    visibility: visible;
}

.project:hover > .project_details > .titles {
    transform: translateX(0);
    opacity: 1;
}

.project:hover > .project_details > .links {
    transform: translateX(0);
    opacity: 1;
}

.project_details {
    visibility: hidden;
    background-color: var(--main-color);
    transition: filter 0.3 ease-in-out;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding: 4rem 0;
    border-radius: 0.5rem;
    overflow-x: hidden;
    opacity: 0;
}

.titles {
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transform: translateX(-20rem);
    opacity: 0;
    padding: 0 1rem;
}

.links {
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transform: translateY(3rem);
    opacity: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 10rem;
}

.link_item {
    display: flex;
    flex-direction: column;
    height: 7rem;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

@media (hover: hover) {
    .link_item:hover {
        opacity: 0.6;
    }
}

.link_item_text {
    font-size: 1.6rem;
    font-weight: bold;
    margin-top: 10px;
}
