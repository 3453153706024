.wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapper > * {
    margin: 1rem;
}

@media (max-width: 620px) {
    .wrapper > * {
        margin: 1rem 0;
    }
}

.list {
    display: flex;
    width: 100%;
    padding: 2rem;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.list > * {
    margin: 0 4rem;
}

@media (max-width: 450px) {
    .list {
        justify-content: space-between;
        padding: 2rem;
    }
    .list > * {
        margin: 0;
    }
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.lang_name {
    font-size: 2.2rem;
    font-weight: bold;
    color: #888;
}

@media (max-width: 330px) {
    .lang_name {
        font-size: 1.8rem;
        font-weight: bold;
        color: #888;
    }
}

.image_container {
    background-color: #fff;
    padding: 1rem;
    height: 9rem;
    width: 9rem;
    box-shadow: var(--shadow-default);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.image {
    height: 5.5rem;
}

@media (max-width: 330px) {
    .image_container {
        height: 7rem;
        width: 7rem;
    }

    .image {
        height: 3.5rem;
    }
}
