.container {
    max-width: 100rem;
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.list {
    display: flex;
    width: 100%;

    justify-content: center;
}

@media (max-width: 650px) {
    .list {
        flex-direction: column;
        align-items: center;
    }
}

.list > * {
    margin: 1rem;
}
