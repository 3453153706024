.icon {
    margin-bottom: 1rem;
}

.titles {
    margin-bottom: 1rem;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.body {
    width: 100%;
    height: 100%;
}
