.continer {
    position: relative;
}

.select_color {
    width: 30rem;
    height: 15rem;
    background-color: #fff;
    box-shadow: var(--shadow-default);
    position: absolute;
    border-radius: 1rem;
    padding: 1rem 1.5rem;
    top: 60px;
    left: 50px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    z-index: 500;

    transform: scale(0);
    opacity: 0;
    transform-origin: top left;
    transition: transform 0.3s ease-in-out, opacity 0.4s ease-in-out;
}

@media (max-width: 500px) {
    .select_color {
        transform-origin: top center;
    }
}

.select_color_display {
    transform: scale(1);
    opacity: 1;
}

.color_option {
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    box-shadow: var(--shadow-default);
}

.color_option_wrapper {
    padding: 1rem;
    border-radius: 1rem;
    margin: 0 0.5rem;
    border: 3px solid transparent;
    transition: border-color 0.2s ease-in-out;
    cursor: pointer;
}

.color_option_wrapper:hover {
    border-color: #ccc;
}

.color_option_wrapper_selected {
    border-color: #aaa;
}

.theme {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
}

.selected_color {
    width: 2.7rem;
    height: 2.7rem;
    border-radius: 50%;
    display: inline-block;
    box-shadow: var(--shadow-default);
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;
}

@media (hover: hover) {
    .selected_color:hover {
        opacity: 0.7;
    }
}

.theme_label {
    margin-right: 0.5rem;
    font-weight: bold;
    color: #777;
}
