.wrapper {
    position: relative;
}

.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 50rem;
}

.titles {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333;
    margin-bottom: 3rem;
}

.title {
    font-size: 8rem;
    margin-bottom: 3rem;
}

@media (max-width: 450px) {
    .title {
        font-size: 7rem;
    }
}

@media (max-width: 350px) {
    .title {
        font-size: 6rem;
    }
}
.sub_title {
    font-size: 3rem;
}

@media (max-width: 650px) {
    .sub_title {
        font-size: 2.5rem;
    }
}

.image {
    margin-bottom: 3rem;
}

.social {
    display: flex;
    height: 4rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
}

.social_image {
    height: 4rem;
    width: 4rem;
    margin: 1rem;
}

.social_image:hover {
    opacity: 0.7;
}
