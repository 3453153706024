.item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
}

.icon {
    height: 6rem;
    margin-bottom: 1rem;
}

.name {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    color: #aaa;
}

.description {
    font-size: 1.9rem;
    padding: 1rem;
    line-height: 3rem;
    text-align: center;
    background-color: #eee;
    border-radius: 0.5rem;
    word-wrap: break-word;
}
