.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: var(--main-color);
    z-index: -1;
    margin-top: 7rem;
}

.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.container {
    width: 100%;
    max-width: 120rem;
}

.title {
    font-size: 5rem;
    margin-bottom: 2rem;
    padding: 3rem;
    color: #fff;
    width: 100%;
    text-align: center;
}

.text {
    text-align: center;
}

.list_wrapper {
    padding-right: 5rem;
    padding-left: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 47rem);
    width: 100%;
    align-items: center;
    justify-content: center;
}

@media (max-width: 500px) {
    .container {
        padding: 0;
    }
}

.teaser_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    height: 100%;
}

.teaser {
    width: 70rem;
}
