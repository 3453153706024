.navbar {
    display: flex;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    justify-content: space-between;
    height: 7rem;
    --selected-color: var(--main-color);
    background-color: #fff;
    transition: box-shadow 0.3s ease-in-out;
}

.navbar_shadow {
    box-shadow: var(--shadow-bottom);
}

.navbar_wrapper {
    width: 40rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.navbar_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out;
    justify-content: center;
    height: 100%;
    padding: 0 1rem;
}

.item_selected > .item_text {
    color: var(--selected-color);
}

.item_text {
    transition: color 0.2s ease-in-out;

    color: var(--unselected-color);
    font-size: 2.2rem;
}

.side_wrapper {
    display: flex;
    align-items: center;
    padding: 0 1rem;
}

.open_menu {
    display: none;
}

.list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 6.7rem;
    padding-top: 1rem;
    width: 100%;
}

.indicator {
    width: 100%;
    height: 0.3rem;
    position: relative;
    position: absolute;
    left: 0;
    background-color: var(--selected-color);
    bottom: 0;
    transition: left 0.4s ease-in-out, width 0.4s ease-in-out;
}

@media (max-width: 500px) {
    .open_menu {
        display: flex;
    }
}

@media (max-width: 500px) {
    .navbar_wrapper {
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 50;
        margin-top: 7rem;
        font-weight: bold;
        font-size: 2.5rem;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease-in-out;
    }

    .navbar_wrapper_display {
        opacity: 1;
        visibility: visible;
        background-color: var(--main-color);
        color: #fff;
        transition: opacity 0.3s ease-in-out;
    }

    .navbar_wrapper_display > .list {
        visibility: visible;
    }

    .list {
        visibility: hidden;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 2rem;
        height: 100%;
    }

    .item_text {
        transition: color 0.2s ease-in-out;
        color: #fff;
        font-size: 3.5rem;
    }

    .navbar_item {
        margin: 2rem;
        height: auto;
    }

    .item_selected > .item_text {
        color: #fff;
        text-decoration: underline;
    }

    .indicator {
        display: none;
    }
}
