.sections {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.sections > * > * {
    height: 100%;
    padding-top: 10rem;
}

.section_wrapper {
    display: flex;
    justify-content: center;
    margin: 0;
    min-height: 50rem;
}
